<template>
  <section>
    <div class="loading">
      <div
        class="uil-ring-css"
        style="transform:scale(0.79);"
      >
        <div />
      </div>
    </div>
  </section>
</template>
<script>
export default {
    name: "LoadingSpinner",
};
</script>
